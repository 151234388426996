import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Link, animateScroll as scroll } from 'react-scroll';
import { Div, H3, P, Image, Span, Ul, Li, FontAwesome, Header } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// image
import logoLight from '../../images/logo2.svg'
import logoDark from '../../images/logo.svg'

const menus = ['home', 'about', 'offer', 'booking', 'faq', 'review', 'contact']

const TravelHeaderComponent = ({ className, id }) => {
    return (
        <Header className={className} id={id}>
            <Container>
                <Row>
                    <Col xl={8} md={4} xs={12}>
                        <Div className="logo">
                            <NavLink to="/">
                                <Image className="logoLight" src={logoLight} alt="" />
                                <Image className="logoDark" src={logoDark} alt="" />
                            </NavLink>
                        </Div>
                    </Col>
                    <Col xl={4} lg={5} md={6} className="d-none d-md-block">
                        <Div className="headerContent">
                            <P>Contact@multilan.com</P>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Header>
    )
}
export default TravelHeaderComponent