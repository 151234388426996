import React, { useState } from 'react'
import { Div, Form, Button, FontAwesome } from '../elements'
import { Container, Row, Col, Input } from 'reactstrap'
import SectionTitle from '../SectionTitle'
import './style.scss'

const NewsletterComponent = ({ className }) => {
    const [newsletter, setNewsletter] = useState('')
    const submidhandler = e => {
        e.preventDefault()
        console.log(newsletter)
    }
    return (
        <Div className={className}>
            <Container>
                <Row>
                    <Col lg={{ size: 6, offset: 3 }}>
                        <SectionTitle
                            className="sectionTitle"
                            title="Subscribe Our Newsletter"
                            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                        />
                    </Col>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Form className="newsletterWrap" onSubmit={submidhandler}>
                            <Input
                                type="email"
                                placeholder="Your Email Here..."
                                value={newsletter}
                                onChange={e => setNewsletter(e.target.value)}
                            />
                            <Button><FontAwesome name="paper-plane-o" /></Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Div>
    )
}
export default NewsletterComponent