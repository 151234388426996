import React, { useState } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Masonry from 'react-masonry-component';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import { Div, Image, Span, FontAwesome, H3, P, A, Ul, Li, H2 } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import grap from '../../images/finance/about/grap.png'

const abouts = [
    {
        icon: 'flaticon-growth',
        title: 'Working Capital',
        text: 'It is a long established fact that a reader will be distracted by the readable company.',
    },
    {
        icon: 'flaticon-connection',
        title: 'Working Capital',
        text: 'It is a long established fact that a reader will be distracted by the readable company.',
        className: 'active'
    },
    {
        icon: 'flaticon-collaboration',
        title: 'Working Capital',
        text: 'It is a long established fact that a reader will be distracted by the readable company.',
    },
    {
        icon: 'flaticon-focus',
        title: 'Working Capital',
        text: 'It is a long established fact that a reader will be distracted by the readable company.',
    },
]
const FinanceAboutComponent = ({ className, id, masonry, picture }) => {
    const [video, setVideo] = useState(false)
    return (
        <Div className={className} id={id}>

            <Container>
                <Row>
                    {masonry &&
                        <Col lg={6} xs={12}>
                            <Masonry
                                className='aboutWrapper row'
                            >
                                {abouts.map((about, i) => (
                                    <Col sm={6} xs={12} key={i}>
                                        <Div className={`aboutItem ${about.className}`}>
                                            <Div className="bgShape"></Div>
                                            <Span className={about.icon}></Span>
                                            <H3>{about.title}</H3>
                                            <P>{about.text}</P>
                                        </Div>
                                    </Col>
                                ))}
                            </Masonry>
                        </Col>}
                    <Col lg={6} xs={12}>
                        <Div className="aboutContent">
                            <H2><Span className="lineStyleTwo">You Can Change the World </Span><Span className="d-block">In Your Own Way</Span></H2>
                            <P>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                                    and I will give you a complete account of the system, and expound the actual teachings of the great
                                     explorer of the truth, the master-builder of human happiness. No one rejects, dislikes</P>
                            <Ul>
                                <Li><A className="readmoreBtn" href="#">Know More</A></Li>
                            </Ul>
                        </Div>
                    </Col>
                    {picture && <Col lg={6} xs={12}>
                        <Div className="aboutImg">
                            <Image src={grap} alt="" />
                        </Div>
                    </Col>}
                </Row>
            </Container>
        </Div >
    )
}
export default FinanceAboutComponent