import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Div, H3, P, FontAwesome, Span } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import news1 from '../../images/conferrence/blog/1.jpg'
import news2 from '../../images/conferrence/blog/2.jpg'
import news3 from '../../images/conferrence/blog/3.jpg'

const news = [
    {
        images: news1,
        title: 'See Our Latest Video From Blog Post',
        date: 'April 6, 2018',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        images: news2,
        title: 'See Our Latest Video From Blog Post',
        date: 'April 6, 2018',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        images: news3,
        title: 'See Our Latest Video From Blog Post',
        date: 'April 6, 2018',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
]

const NewsComponent = ({ className, id }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col lg={{ size: 6, offset: 3 }} xs={12}>
                        <SectionTitle
                            className="sectionTitle conferrenceSectionTitle"
                            title="Latest News"
                            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                        />
                    </Col>
                </Row>
                <Row>
                    {news.map((item, i) => (
                        <Col key={i} lg={4} md={6} xs={12}>
                            <Div style={{ background: `url(${item.images}) no-repeat center center / cover` }} className="newsWrap">
                                <H3><Link to="/blog-details">{item.title}</Link></H3>
                                <Span><FontAwesome name="clock-o" />{item.date}</Span>
                                <P>{item.text}</P>
                            </Div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Div>
    )
}
export default NewsComponent