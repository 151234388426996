import React, { useState } from 'react'
import { Row, Col, Container, Input } from 'reactstrap'
import { GoogleMap, LoadScript, GroundOverlay } from '@react-google-maps/api';
import Slider from "react-slick";
import { Div, Image, Span, FontAwesome, H3, P, A, H2, Form, Button, Ul, Li } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import test from '../../images/finance/test/1.png'


const FinanceContact = ({ className, id }) => {

    const [newsLetter, setNewsLetter] = useState('')
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <SectionTitle
                            className="sectionTitle financeSectionTitle"
                            title="Contact With Us"
                            text="But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system"
                        />
                    </Col>
                    <Col lg={5} md={8}>
                        <Ul className="contactWrap">
                            <Li>
                                <FontAwesome name="mobile" />
                                <Span>+88 012 56 756 879 </Span>
                                <Span>+88 013 45 860 457</Span>
                            </Li>
                            <Li>
                                <FontAwesome name="envelope-o" />
                                <Span>info@aborton.com</Span>
                                <Span>contact@aborton.com</Span>
                            </Li>
                            <Li>
                                <FontAwesome name="map-marker" />
                                39 Your address road name,
                                 <Span>United State.</Span>
                            </Li>
                        </Ul>
                    </Col>
                </Row>
            </Container>
            <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyAl4MhQsDTMNst0C9PlNg_91AHMM31-68M"
            >
                <GoogleMap
                    id="circle-example"
                    zoom={2}
                    center={{
                        lat: 40.740,
                        lng: -74.18
                    }}
                >
                    <GroundOverlay
                        key={'url'}
                        bounds={{
                            north: 40.773941,
                            south: 40.712216,
                            east: -74.12544,
                            west: -74.22655
                        }}
                    />
                </GoogleMap>
            </LoadScript >
        </Div >
    )
}
export default FinanceContact