import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Div, H3, A, Li, Ul, FontAwesome, Image, Span } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images

import speaker1 from '../../images/conferrence/speker/1.jpg'
import speaker2 from '../../images/conferrence/speker/2.jpg'
import speaker3 from '../../images/conferrence/speker/3.jpg'
import speaker4 from '../../images/conferrence/speker/4.jpg'
import speaker5 from '../../images/conferrence/speker/5.jpg'
import speaker6 from '../../images/conferrence/speker/6.jpg'

const speakers = [
    { name: 'John Daow', designation: 'UX Designer', image: speaker1 },
    { name: 'John Daow', designation: 'UX Designer', image: speaker2 },
    { name: 'John Daow', designation: 'UX Designer', image: speaker3 },
    { name: 'John Daow', designation: 'UX Designer', image: speaker4 },
    { name: 'John Daow', designation: 'UX Designer', image: speaker5 },
    { name: 'John Daow', designation: 'UX Designer', image: speaker6 },
]

const SpeakerComponent = ({ className,id }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col lg={{ size: 6, offset: 3 }} xs={12}>
                        <SectionTitle
                            className="sectionTitle conferrenceSectionTitle"
                            title="Our Speaker"
                            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                        />
                    </Col>
                    {speakers.map((speaker, i) => (
                        <Col key={i} lg={4} sm={6} xs={12}>
                            <Div className="speakerWrap">
                                <Div className="speakerImg">
                                    <Ul className="speakerIcon">
                                        <Li><A href="#"><FontAwesome name="facebook" /></A></Li>
                                        <Li><A href="#"><FontAwesome name="linkedin" /></A></Li>
                                        <Li><A href="#"><FontAwesome name="twitter" /></A></Li>
                                    </Ul>
                                    <Image src={speaker.image} alt="" />
                                </Div>
                                <Div className="speakerContent">
                                    <H3>{speaker.name}</H3>
                                    <Span>{speaker.designation}</Span>
                                </Div>
                            </Div>
                        </Col>
                    ))}

                </Row>
            </Container>
        </Div>
    )
}
export default SpeakerComponent