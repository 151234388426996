import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom'
import BookLanding from '../BookLanding'
import ConferrenceLanding from '../Conferrence'
import FinanceLanding from '../Finance'
import ResturentLanding from '../Restaurent'
import TravelLanding from '../Travel'
import PreviewPage from '../PreviewPage'
import NotFoundPage from '../404'
import './style.scss';

const App = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/" exact component={PreviewPage} />
        <Route path="/travel-landing" exact component={TravelLanding} />
        <Route path="/restaurent-landing" exact component={ResturentLanding} />
        <Route path="/finance-landing" exact component={FinanceLanding} />
        <Route path="/conferrence-landing" exact component={ConferrenceLanding} />
        <Route path="/book-landing" exact component={BookLanding} />
        <Route component={NotFoundPage} />
      </Switch>
    </Fragment>
  );
}

export default App;
