import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Div, H3, H2, H4, P, Image, Span, Ul, Li, FontAwesome, A } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import about from '../../images/travel/about/1.png'


const TravelAbout = ({ className, id }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col xl={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 2 }}>
                        <SectionTitle
                            className="sectionTitle travelSectionTitle"
                            title="About Us"
                            text="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={{ size: 6, offset: 1 }} lg={7} className="d-none d-lg-block">
                        <Div className="aboutImage">
                            <Image src={about} />
                        </Div>
                    </Col>
                    <Col lg={5} xs={12}>
                        <Div className="aboutContent">
                            <H3>We Are not only travel agency but also travel lover. </H3>
                            <P>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.</P>
                            <Span><A href="#">Learn More</A></Span>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div>
    )
}
export default TravelAbout