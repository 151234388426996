import React, { useState } from 'react'
import { Row, Col, Container, Input } from 'reactstrap'
import Slider from "react-slick";
import { Div, Image, Span, FontAwesome, H3, P, A, H2, Form, Button } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import test from '../../images/finance/test/1.png'


const FinanceNewsLetter = ({ className, id }) => {

    const [newsLetter, setNewsLetter] = useState('')
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Div className="newsletterWrap">
                            <Div className="newsletterCircle"></Div>
                            <H2>Subscribe Our Newsletter </H2>
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1 }}>
                                    <Form className="formStyle">
                                        <Input
                                            type="text"
                                            placeholder="Your Email Address..."
                                            onChange={e => setNewsLetter(e.target.value)}
                                            value={newsLetter}
                                        />
                                        <Button>Send Now</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div >
    )
}
export default FinanceNewsLetter