import React, { Fragment } from 'react'
import TravelHeaderComponent from '../../components/TravelHeader'
import TravelHeroComponent from '../../components/TravelHero'
import MenuSidebarComponent from '../../components/MenuSidebar'
import WhyChoosComponent from '../../components/WhyChoos'
import TravelAbout from '../../components/TravelAbout'
import BestOfferComponent from '../../components/BestOffer'
import BannerComponent from '../../components/Banner'
import ClientReviewComponent from '../../components/ClientReview'
import TravelFaqComponent from '../../components/TravelFaq'
import RastaurentContactComponent from '../../components/RastaurentContact'
import RastaurentFooterComponent from '../../components/RastaurendFooter'
// images
import logo from '../../images/logo.svg'

const TravelLanding = () => {
    return (
        <Fragment>
            <TravelHeaderComponent
                className="travelHeaderArea"
            />
            <MenuSidebarComponent
                className="menuSidebarArea"
            />
            <TravelHeroComponent
                className="travelHeroArea"
                id="home"
            />
            <WhyChoosComponent
                className="whyChoosArea"
                id="featured"
            />
            <TravelAbout
                className="travelAboutArea"
                id="about"
            />
            <BestOfferComponent
                className="bestOfferArea"
                id="offer"
            />
            <BannerComponent
                className="bannerArea"
            />
            <TravelFaqComponent
                className="travelFaqArea"
                id="faq"
            />
            <ClientReviewComponent
                className="clientReviewArea"
                id="review"
            />
            <RastaurentContactComponent
                className="rastaurentContactArea travelContactArea"
                id="contact"
                travel={true}
            />
            <RastaurentFooterComponent
                className="rastaurentFooterArea"
            />
        </Fragment>
    )
}
export default TravelLanding