import React, { useState } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Slider from "react-slick";
import { Div, Image, Span, FontAwesome, H3, P, A, Ul, Li, H2 } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import project1 from '../../images/finance/project/1.jpg'
import project2 from '../../images/finance/project/2.jpg'
import project3 from '../../images/finance/project/3.jpg'

const ProjectComponent = ({ className, id }) => {
    return (
        <Div className={className} id={id}>

            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <SectionTitle
                            className="sectionTitle financeSectionTitle"
                            title="Our Finished Projects"
                            text="But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} sm={6} xs={12}>
                        <Div className="projectContent">
                            <H3>We Are Creative Company</H3>
                            <P>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account.</P>
                        </Div>
                    </Col>
                    <Col lg={3} sm={6} xs={12}>
                        <Div className="projectWrap">
                            <Image src={project1} alt="" />
                            <Div className="projectInfo">
                                <A href="#">View Case Study  <FontAwesome name="angle-double-right" /></A>
                            </Div>
                        </Div>
                    </Col>
                    <Col lg={3} sm={6} xs={12}>
                        <Div className="projectWrap">
                            <Image src={project2} alt="" />
                            <Div className="projectInfo">
                                <A href="#">View Case Study  <FontAwesome name="angle-double-right" /></A>
                            </Div>
                        </Div>
                    </Col>
                    <Col lg={3} sm={6} xs={12}>
                        <Div className="projectWrap">
                            <Image src={project3} alt="" />
                            <Div className="projectInfo">
                                <A href="#">View Case Study  <FontAwesome name="angle-double-right" /></A>
                            </Div>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div >
    )
}
export default ProjectComponent