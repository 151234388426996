import React, { Component } from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import { Div, H3, P, Image, Span, FontAwesome, H2, A, Ul, Li, Form, Button } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'


class RastaurentContactComponent extends Component {
    state = {
        massage: '',
        name: '',
        email: '',
        subject: ''
    }
    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        return (
            <Div className={this.props.className} id={this.props.id}>
                <Container>
                    <Row>
                        {this.props.travel ? <Col xl={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 2 }}>
                            <SectionTitle
                                className="sectionTitle travelSectionTitle"
                                title="Contact With Us"
                                text="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti."
                            />
                        </Col> : <Col lg={{ size: 8, offset: 2 }}>
                                <SectionTitle
                                    className="sectionTitle rastaurentSectionTitle"
                                    title="CONTACT"
                                    colorText="With Us"
                                    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
                                />
                            </Col>}

                    </Row>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Div className="contactInfo">
                                <H3>Contact Info</H3>
                                <Ul>
                                    <Li>
                                        <FontAwesome name="phone" />
                                        <Span>+88 012 345 678 87</Span>
                                        <Span>+88 013 439 596 78</Span>
                                    </Li>
                                    <Li>
                                        <FontAwesome name="envelope" />
                                        <Span>info@multilan.com</Span>
                                        <Span>contact@multilan.com</Span>
                                    </Li>
                                    <Li>
                                        <FontAwesome name="map-marker" />
                                        <Span>35 Rahim Plaza , Shakpara </Span>
                                        <Span> Khulna , Bangladesh</Span>
                                    </Li>
                                </Ul>
                            </Div>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Div className="contactForm">
                                <H3>Get In Touch</H3>
                                <Form>
                                    <Row>
                                        <Col lg={6}>
                                            <Input
                                                type="text"
                                                placeholder="Name"
                                                value={this.state.name}
                                                onChange={this.changeHandler}
                                                name="name"
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                value={this.state.email}
                                                onChange={this.changeHandler}
                                                name="email"
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Input
                                                type="text"
                                                placeholder="Subject"
                                                value={this.state.subject}
                                                onChange={this.changeHandler}
                                                name="subject"
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Input
                                                name="massage"
                                                type="textarea"
                                                value={this.state.massage}
                                                onChange={this.changeHandler}
                                                name="massage"
                                            />
                                        </Col>
                                        <Col xs={12} className="text-right">
                                            <Button>Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Div>
                        </Col>
                    </Row>
                </Container>
            </Div>
        )
    }
}
export default RastaurentContactComponent