import React, { Component } from 'react'
import { GoogleMap, LoadScript, GroundOverlay } from '@react-google-maps/api';
import { Row, Col, Container, Input } from 'reactstrap'
import { Div, P, Span, H3, Form, Button } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'


class ContactComponent extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    }
    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    submitHandler = (e) => {
        e.preventDefault()
    }
    render() {
        return (
            <Div className={this.props.className} id={this.props.id}>
                <Container>
                    <Row>
                        <Col lg={{ size: 6, offset: 3 }} xs={12}>
                            <SectionTitle
                                className="sectionTitle conferrenceSectionTitle"
                                title="Contact Us"
                                text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Div className="contactForm">
                                <H3>Get a quote</H3>
                                <Form onSubmit={this.submitHandler}>
                                    <Input
                                        placeholder="Name"
                                        value={this.state.name}
                                        onChange={this.changeHandler}
                                        name="name"
                                    />
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.changeHandler}
                                        name="email"
                                    />
                                    <Input
                                        type="tel"
                                        placeholder="Phone"
                                        value={this.state.phone}
                                        onChange={this.changeHandler}
                                        name="phone"
                                    />
                                    <Input
                                        placeholder="Subject"
                                        value={this.state.subject}
                                        onChange={this.changeHandler}
                                        name="subject"
                                    />
                                    <Input
                                        type="textarea"
                                        placeholder="Message"
                                        value={this.state.message}
                                        onChange={this.changeHandler}
                                        name="message"
                                    />
                                    <Button>Send</Button>
                                </Form>
                            </Div>
                        </Col>
                        <Col lg={8}>
                            <Div className="contactInfo">
                                <Row>
                                    <Col lg={4}>
                                        <H3>Address</H3>
                                        <P>Level 13, 2 Elizabeth St, Melbourne, 3000, USA</P>
                                    </Col>
                                    <Col lg={4}>
                                        <H3>E-mail</H3>
                                        <P>info@yourdomain.com <Span>support@yourdomain.com</Span></P>
                                    </Col>
                                    <Col lg={4}>
                                        <H3>Call us</H3>
                                        <P>+01 000 0000 000 <Span> +44 000 0000 000</Span></P>
                                    </Col>
                                </Row>
                                <LoadScript
                                    id="script-loader"
                                    googleMapsApiKey="AIzaSyAl4MhQsDTMNst0C9PlNg_91AHMM31-68M"
                                >
                                    <GoogleMap
                                        id="circle-example"
                                        zoom={2}
                                        center={{
                                            lat: 40.740,
                                            lng: -74.18
                                        }}
                                    >
                                        <GroundOverlay
                                            key={'url'}
                                            bounds={{
                                                north: 40.773941,
                                                south: 40.712216,
                                                east: -74.12544,
                                                west: -74.22655
                                            }}
                                        />
                                    </GoogleMap>
                                </LoadScript >
                            </Div>
                        </Col>
                    </Row>
                </Container >
            </Div >
        )
    }

}
export default ContactComponent