import React, { Fragment } from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Div, H3, P, FontAwesome, Span, Image, A, Footer } from '../../components/elements'
import './style.scss'


// images
import logo from '../../images/logo2.svg'

import travel from '../../images/preview/demo/travel.jpg'
import book from '../../images/preview/demo/book.jpg'
import conferrence from '../../images/preview/demo/conferrence.jpg'
import finance from '../../images/preview/demo/finance.jpg'
import restaurent from '../../images/preview/demo/restaurent.jpg'
import reactstrap from '../../images/preview/featured/reactstrap.png'
import reactRouterDom from '../../images/preview/featured/reactRouterDom.png'
import sass from '../../images/preview/featured/sass.svg'
import bootstrap from '../../images/preview/featured/bootstrap.png'
import slick from '../../images/preview/featured/slick.png'
import reactLogo from '../../images/preview/featured/react.svg'
import reduxLogo from '../../images/preview/featured/redux.svg'
import fontawesome from '../../images/preview/featured/fontawesome.png'

const demos = [
    { image: travel, title: 'Travel Landing', link: 'travel-landing' },
    { image: book, title: 'book Landing', link: 'book-landing' },
    { image: conferrence, title: 'conferrence Landing', link: 'conferrence-landing' },
    { image: finance, title: 'finance Landing', link: 'finance-landing' },
    { image: restaurent, title: 'restaurent Landing', link: 'restaurent-landing' },
]

const featureds = [
    { image: reactLogo, title: 'react app', link: 'https://reactjs.org/' },
    { image: reduxLogo, title: 'Redux', link: 'https://redux.js.org/' },
    { image: reactRouterDom, title: 'React Router Dom', link: 'https://reacttraining.com/react-router/' },
    { image: reactstrap, title: 'Reactstarp', link: 'https://reactstrap.github.io/' },
    { image: sass, title: 'Sass', link: 'https://sass-lang.com/' },
    { image: bootstrap, title: 'Bootstrap 4', link: 'https://getbootstrap.com/' },
    { image: slick, title: 'React Slick Slider', link: 'https://react-slick.neostack.com/' },
    { image: fontawesome, title: 'Fontawesome Icon', link: 'https://fontawesome.com/' },
]

const PreviewPage = ({ className, id }) => {
    return (
        <Fragment>
            <Div className="previewHeroArea">
                <Container>
                    <Row>
                        <Col lg={{ size: 6, offset: 3 }}>
                            <Div className="previewHeroContent">
                                <Image src={logo} />
                                <H3>MultiLan - React Multipurpose Landing page</H3>
                                <A href="#">Buy Now</A>
                            </Div>
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Div className="previewDemoArea">
                <Container>
                    <Row>
                        {demos.map((demo, i) => (
                            <Col key={i} lg={4} md={6} xs={12}>
                                <Div className="previewDemoWrap">
                                    <Div className="previewDemoImg">
                                        <Link  target="_blank" to={demo.link}>
                                            <Image src={demo.image} alt="" />
                                        </Link>
                                    </Div>
                                    <Div className="previewDemoContent">
                                        <H3><Link  target="_blank" to={demo.link}>{demo.title}</Link></H3>
                                    </Div>
                                </Div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Div>
            <Div className="keyFeaturedArea">
                <Container>
                    <Row>
                        <Col lg={{ size: 6, offset: 3 }}>
                            <Div className="sectionTitle">
                                <H3>Key Featured this Product</H3>
                                <P>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus enim eius illo atque quasi illum.</P>
                            </Div>
                        </Col>
                    </Row>
                    <Row>
                        {featureds.map((featured, i) => (
                            <Col key={i} lg={3} md={4} sm={6} xs={12}>
                                <Div className="previewFeaturedWrap">
                                    <A target="_blank" href={featured.link}>
                                        <Image src={featured.image} />
                                        <Span>{featured.title}</Span>
                                    </A>
                                </Div>
                            </Col>
                        ))}

                    </Row>
                </Container>
            </Div>
            <Div className="ourcontent">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <H3>Lorem ipsum dolor consectetur adipisicing aliquid repudiandae earum quas aliquam</H3>
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer className="footerArea">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <P>Copyright &copy; 2019 All right reserved.</P>
                        </Col>
                    </Row>
                </Container>
            </Footer>
        </Fragment>
    )
}
export default PreviewPage