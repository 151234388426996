import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link, animateScroll as scroll } from 'react-scroll';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import { Div, H3, H2, H4, P, Image, Span, Ul, Li, FontAwesome, A } from '../elements'
import './style.scss'

import hero from '../../images/travel/hero/1.png'

const TravelHeroComponent = ({ className, id }) => {
    const [video, setVideo] = useState(false)
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col xl={{ size: 6, offset: 6 }} lg={9} md={11} xs={12}>
                        <Div className="heroWrappper">
                            <H3>Enjoy Your Tour With US</H3>
                            <H2>Tour <Span>Lover</Span> People</H2>
                            <P>There are many variations of passages of Lorem Ipsum available, but the of majority have suffered alteration in some form, by injected randomised. going to use a passage of Lorem Ipsum, you need to be sure there isn't anything in embarrassing hidden in the middle of text.</P>
                            <Ul>
                                <Li className="loadmore"><A href="#">Learn More</A></Li>
                                <Li className="scrlMeDown">
                                    <Link
                                        spy
                                        smooth
                                        offset={0}
                                        duration={1000}
                                        to="featured">
                                    </Link>
                                </Li>
                            </Ul>
                            <Span className="videoWrap" onClick={() => setVideo(true)} >
                                <FontAwesome name="play" />
                            </Span>
                            <ModalVideo
                                channel='youtube'
                                isOpen={video}
                                onClose={() => setVideo(false)}
                            />
                        </Div>
                    </Col>
                </Row>
            </Container>
            <H4>Travel</H4>
            <Div className="heroImage d-none d-md-block">
                <Image src={hero} alt="" />
            </Div>
        </Div>
    )
}
export default TravelHeroComponent