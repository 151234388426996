import React, { Fragment } from 'react'
import HeaderComponent from '../../components/Header'
import HeroArea from '../../components/HeroArea'
import FeaturedComponent from '../../components/Featured'
import AboutComponent from '../../components/About'
import FaqComponent from '../../components/Faq'
import ProfileComponent from '../../components/Portfolio'
import TestmonialComponent from '../../components/Testmonial'
import VideoComponent from '../../components/Video'
import NewsletterComponent from '../../components/NewsLetter'
import FooterComponent from '../../components/Footer'

// images
import logo from '../../images/logo2.svg'

const menus = [
    { name: 'Home', id: 'home' },
    { name: 'about', id: 'about' },
    { name: 'faqs', id: 'faqs' },
    { name: 'book', id: 'book' },
    { name: 'testmonial', id: 'testmonial' },
    { name: 'review', id: 'review' },
]

const BookLanding = () => {
    return (
        <Fragment>
            <HeaderComponent
                className="bookHeaderArea"
                button="Download"
                fontawesome="cloud-upload"
                menus={menus}
                logo={logo}
            />
            <HeroArea
                className="bookHeroArea"
                id="home"
                title="Books are the best friend of people"
                text="Showcase your new book with a beautiful cover shot, author bio, and sneak peek at what’s inside. With
                these book landing page themes, you can drive immediate sales from."
                showVideo="L61p2uyiMSo"
                button="Know More"
                books={true}
            />
            <FeaturedComponent className="featuredArea" />
            <AboutComponent className="aboutArea" id="about" />
            <FaqComponent className="faqArea" id="faqs" />
            <ProfileComponent className="portfolioArea" id="book" />
            <TestmonialComponent className="testmonialArea" id="testmonial" />
            <VideoComponent className="videoArea" id="review" />
            <NewsletterComponent className="newsletterArea" />
            <FooterComponent className="footerArea bookFooterArea" />
        </Fragment>
    )
}
export default BookLanding