import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import { Div, H3, H2, H4, P, Image, Span, Ul, Li, FontAwesome, A } from '../elements'
import SectionTitle from '../SectionTitle'
import 'react-accessible-accordion/dist/fancy-example.css';
import './style.scss'

const faqs = [
    {
        title: 'Q.1 Why people go with you ?',
        text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.Et harum quidem rerum facilis libero tempore.Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore rerum facilis .',
        id:'1',
    },
    {
        title: 'Q.2 Why people go with you ?',
        text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.Et harum quidem rerum facilis libero tempore.Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore rerum facilis .',
        id:'2',
    },
    {
        title: 'Q.3 Why people go with you ?',
        text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.Et harum quidem rerum facilis libero tempore.Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore rerum facilis .',
        id:'3',
    },
    {
        title: 'Q.4 Why people go with you ?',
        text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.Et harum quidem rerum facilis libero tempore.Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore rerum facilis .',
        id:'4',
    },
]


const TravelFaqComponent = ({ className, id }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col xl={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 2 }}>
                        <SectionTitle
                            className="sectionTitle travelSectionTitle"
                            title="FAQS"
                            text="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Accordion preExpanded={['1']}>
                            {faqs.map((faq,i) => (
                                <AccordionItem uuid={faq.id} key={i}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {faq.title}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <P>{faq.text}</P>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}

                        </Accordion>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Div className="faqImg">
                            <P>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis
                                est eligendi optio cumque nihil impedit quo minus.Et harum quidem rerum facilis libero tempore.Et harum quidem rerum facilis</P>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div>
    )
}
export default TravelFaqComponent