import React, { Fragment } from 'react'
import HeaderComponent from '../../components/Header'
import HeroArea from '../../components/HeroArea'
import AboutCoundownComponent from '../../components/AboutCountDown'
import SpeakerComponent from '../../components/speaker'
import ScheduleComponent from '../../components/Schedule'
import GalleryComponent from '../../components/Gallary'
import NewsComponent from '../../components/News'
import ContactComponent from '../../components/Contact'
import ConferrenceFooter from '../../components/ConferrenceFooter'
import ConferrenceFaq from '../../components/ConferrenceFaq'
import SponsorComponent from '../../components/Sponcer'
// images
import logo from '../../images/logo2.svg'

const menus = [
    { name: 'Home', id: 'home' },
    { name: 'Speaker', id: 'speaker' },
    { name: 'Schedule', id: 'schedule' },
    { name: 'Gallary', id: 'gallary' },
    { name: 'News', id: 'news' },
    { name: 'Contact', id: 'contact' },
]

const ConferrenceLanding = () => {
    return (
        <Fragment>
            <HeaderComponent
                menus={menus}
                className="conferrenceHeaderArea"
                logo={logo}
            />
            <HeroArea
                conferrence={true}
                className="conferrenceHeroArea"
                id="home"
                subTitle="UI/UX DESIGNER"
                title="THE ANNUAL CONFERENCE 2019"
                text="2 TRACK, 5 CONFERENCE DAYS, 8 WORKSHOPS, 19 EXCELLENT SPEAKERS
                AND JUST 600 AVAILABLE SEATS."
                button="Reservation"
            />
            <AboutCoundownComponent 
                className="aboutCountdownArea"
            />
            <SpeakerComponent 
                className="speakerArea"
                id="speaker"
            />
            <ScheduleComponent 
                className="scheduleArea" 
                id="schedule" 
            />
            <GalleryComponent 
                className="galleryArea"
                id="gallary" 
            />
            <ConferrenceFaq
                className="conferrenceFaqArea"
            />
            <SponsorComponent
                className="sponsorArea"
            />
            <NewsComponent
                className="newsArea" 
                id="news"
            />
            <ContactComponent 
                className="contactArea" 
                id="contact" 
            />
            <ConferrenceFooter
                className="conferrenceFooterArea"
            />
        </Fragment>
    )
}
export default ConferrenceLanding