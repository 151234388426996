import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Div, H3, H2, H4, P, Image, Span, Ul, Li, FontAwesome, A } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'


const WhyChoosComponent = ({ className, id }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Div className="whyChoosWrap">
                    <Row>
                        <Col md={6}>
                            <H3>Hello Nice People</H3>
                            <H2>Are you still intarested to tour this time?</H2>
                        </Col>
                        <Col xl={5} md={6}>
                            <P>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going</P>
                        </Col>
                    </Row>
                </Div>
                <Row>
                    <Col lg={4} sm={6} xs={12}>
                        <Div className="whyChoosItem">
                            <Span className="flaticon-suitcase"></Span>
                            <H4>Value for Money</H4>
                            <P>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.</P>
                        </Div>
                    </Col>
                    <Col lg={4} sm={6} xs={12}>
                        <Div className="whyChoosItem">
                            <Span className="flaticon-tent"></Span>
                            <H4>Beautiful Places</H4>
                            <P>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.</P>
                        </Div>
                    </Col>
                    <Col lg={4} sm={6} xs={12}>
                        <Div className="whyChoosItem">
                            <Span className="flaticon-plane"></Span>
                            <H4>Passionate Travel</H4>
                            <P>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.</P>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div>
    )
}
export default WhyChoosComponent